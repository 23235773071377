import React from 'react';

function HeaderCaretDown() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path
        d="M11.3557 10.5C11.8549 10.5 12.2017 10.2779 12.3962 9.83369C12.5907 9.38949 12.5119 8.99565 12.1599 8.65217L9.27596 5.82609C9.16504 5.71739 9.04488 5.63587 8.91547 5.58152C8.78606 5.52717 8.64741 5.5 8.49952 5.5C8.35162 5.5 8.21297 5.52717 8.08356 5.58152C7.95415 5.63587 7.83399 5.71739 7.72307 5.82609L4.83911 8.65217C4.48786 8.99638 4.40948 9.39058 4.60396 9.83478C4.79844 10.279 5.14489 10.5007 5.64329 10.5H11.3557Z"
        fill="#FEFFFE"
      />
    </svg>
  );
}

export default HeaderCaretDown;
