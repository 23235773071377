import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"weight\":\"400\",\"subsets\":[\"latin\",\"cyrillic\"],\"variable\":\"--font-open-sans\",\"display\":\"swap\"}],\"variableName\":\"open_sans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../fonts/Tahoma.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../fonts/Tahoma-Bold.woff2\",\"weight\":\"800\",\"style\":\"normal\"}],\"display\":\"swap\",\"variable\":\"--font-tahoma\"}],\"variableName\":\"tahoma\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../fonts/Mont-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"}],\"display\":\"swap\",\"variable\":\"--font-mont\"}],\"variableName\":\"mont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/providers/LayoutProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/providers/ReactQueryProvider.tsx");
